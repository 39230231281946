.reasons-and-deals {
	background-color: #f4f3f3;
	padding-top:10px;
	padding-bottom:30px;

	h3 {
		text-transform:uppercase;
		padding-bottom:15px;
		border-bottom: solid 1px #ccc;
	}
	img {
		width: 100%;
	}
	.tick {
		width:100%;
		float:left;
	}
	ul {
		padding-left: 0;
	}
	li {
		list-style-type: none;
		margin-top:30px;
		strong {
			text-transform:uppercase;
			font-weight: 400;
			font-size: 19px;
		}
		p {
			font-weight: 300;

		}
	}
}
