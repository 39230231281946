@import "navbar/admin";
@import "navbar/guest";

.navbar-base {
	@extend .navbar;
	@extend .navbar-inverse;
	background-color: $darkGrey;
  border-color: darken($darkGrey, 5);
	border-radius: 0px;
}


.breadcrumbArea {
	background-color:#F5F5F5;
}

.navbg {
    background-color: #EF7A19;
    margin-top: 20px;
		.menu {
			margin-top: 0px;
			padding-top: 0px;
		}
		.nav-pills {
			li {
				a {
					color: #fff !important;
					&:hover {
						background-color: #EF8A37 !important;
					}
				}
			}
		}
}
