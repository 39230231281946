.header {
	margin-top:40px;
	width:100%;
	border-bottom:solid 1px #ccc;
}
.header--menu {
	ul {
		margin-top:20px;
		list-style:none;
		li {
			display:inline;
		}
	}
}

