

.text-left {
	text-align: left;
	p {
		text-align: left;
	}
}.padding{
	padding:10px;
}
.right{
	@extend .pull-right;
	@extend .text-right;
}


.image-bg-fixed-height {
    height: 450px;
}

.breadcrumb > li + li::before {
    content: "/\00a0" !important;
    }

    .breadcrumb {
    margin-bottom: 0px !important;
    }

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
 }

 .nav-pills > li > a {
    border-radius: 0px !important;
    border-right: 1px solid #F5F5F5;
    }

    .gallery img {
    margin-bottom:3px;
    }




ul.features
{
    list-style-type: none;
}

ul.features li
{
    text-indent: -1.4em;
}

ul.features li:before
{
    font-family: 'FontAwesome';
    content: '\f067';
    margin:0 5px 0 -15px;
    color: #f00;
}

a {
    color:#333333!important;
}

.footer--links li a {
    color:#fff!important;
}

.features a:hover {
    text-decoration:none!important;
}

.contactform .row {
    margin-top:5px;
}

.text-left {
	text-align: left;
	p {
		text-align: left;
	}
}
