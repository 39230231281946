.footer {
	background-color: $orange;
	color: #fff;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-top:20px;
	&--quote {
		@extend .col-sm-4;
		.row {
			margin-top:20px;
		}
	}
	&--quote-reference {
		margin-top: 15px;
	}
	.row {
		margin-left:-15px;
		margin-right:-15px;
		.col-xs-9 {
			margin-left:-15px;
		}
	}
	&--contact {
		font-weight:300;
	}
	&--links {
		@extend .col-sm-3;
		ul {
			padding-left:0;
		}
		li {
			list-style-type: none;
			font-weight:300;
		}
	}
	&--form-control {
		@extend .form-control;
		background-color: #ca6814;
		border-color: #ca6814;
		color: #fff;
		&::-webkit-input-placeholder {
			color: darken(#fff,10);
		}
		&:-moz-placeholder { /* Firefox 18- */
			color: darken(#fff,10);
		}

		&::-moz-placeholder {  /* Firefox 19+ */
			color: darken(#fff,10);
		}

		&:-ms-input-placeholder {  
			color: darken(#fff,10);
		}
	}
	&--form-inline {
		@extend .form-inline;
		margin-top:15px;
		margin-bottom:15px;
	}
	&--get-social {
		width: 100%;
		max-width: 405px;
		margin-top: 15px;
	}
}
