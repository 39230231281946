.home-tiles {
	&--row {
		@extend .row;
		margin-bottom:10px;
		margin-left:10px;
		margin-right:10px;
	}
	&--image {
		background-color:#000;
		z-index:0;
	}
	&--tile {
		max-height: 270px;
		overflow:hidden;
		padding-left:5px;
		padding-right:5px;
		//opacity:1;
		&--hover {
			position:absolute;
			top:0;
			right:5px;
			bottom:0;
			left:5px;
			transition: background-color 0.5s ease;
			-ms-transition: background-color 0.5s ease;
			-moz-transition: background-color 0.5s ease;
			-webkit-transition: background-color 0.5s ease;
			&:hover {
				//background-image: url(/images/layout/transparency.png);
				background-color:rgba(0,0,0,0.5);
				z-index:0;
			}
		}
		a:hover &--read-more {
			display:block;
		}
		h2 {
			position: absolute;
			top: 20px;
			left: 30px;
			color:#fff;
			margin-top:0;
			text-transform: uppercase;
			font-size: 23px;
			font-weight:400;
			//opacity:1;
			z-index:1;
		}
		img {
			//opacity: 1;
			width:100%;
		}
		&--read-more {
			display:none;
			position:absolute;
			bottom: 20px;
			left: 30px;
			font-size: 22px;
			color:#fff;
			text-transform:uppercase;
			font-weight: 300;
			z-index:1;
			&:before {
				content: "+ ";
			}
			&__green, &__teal, &__blue, &__orange {
				@extend .home-tiles--tile--read-more;
			}
			&__green {
				border-bottom: solid 4px #9bd532;
			}
			&__teal {
				border-bottom: solid 4px #3cbdae;
			}
			&__blue {
				border-bottom: solid 4px #2855ae;
			}
			&__orange {
				border-bottom: solid 4px #ed6321;
			}
		}

		&__short {
			@extend .col-md-4;
		}
		&__long {
			@extend .col-md-8;
		}
	}
}
