@import "colors";
@import "fonts";
@import "mixins";

/* TheStudio4 CSS */

h1 {
	font-size: 26px !important;
}

.main {
	border-bottom: solid 1px #ccc;
	margin-bottom: 30px;
	padding-bottom: 30px;
}

.btn-footer {
	color: #fff;
	background-color: darken($orange, 40);
	border-color: darken($orange, 40);
	&:hover, &:focus {
		color: #fff;
	}
}
