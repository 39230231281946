.welcome {
	@extend .row;
	margin-top:20px;
	margin-bottom: 30px;
	h1, h2, h3 {
		color: $orange;
		text-transform: uppercase;
		margin-top:10px;
	}
	h1 {
		font-size: 35px;
		font-weight:300;
		text-align: left;
	}
	h2 {
		font-size:19px;
		font-weight: 700;
	}
	h3 {
		font-size: 20px;
		font-weight: 300;
	}
	p {
		font-weight: 300;
		font-size: 14px;
		.action {
			position:absolute;
			bottom:0;
		}
	}
	div img {
		width: 100%;
	}
}
