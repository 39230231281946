.menu{
	padding-top:10px;
	font-size:16px;
	margin-top: 10px;

	a {
		color:#333;
		padding-left:20px;
		padding-right:20px;
		font-weight:300;
	}

	ul li {
		display:inline;
		color: #cccccc;
	}
	ul {
		padding-left:20px;

	}
}
