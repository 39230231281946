.top-bar {
  ul {
    list-style: none;
    display: inline-block;
    margin-bottom: 0px;
    padding-left: 0px;
    font-size: 0px;
    vertical-align: top;
    li {
      display: inline-block;
      padding: 10px 25px;
      background-color: #f1f1f1;
      vertical-align: top;
      a {
        font-size: 14px;
        text-decoration: none;
      }
      &:hover {
        background-color: darken(#f1f1f1, 10%);
        a {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
    .active {
      background-color: #EF7A19;
      padding: 15px 30px;
      a {
        color: #fff !important;
        font-weight: bold;
        text-decoration: none;
      }
      &:hover {
        background-color: darken(#EF7A19, 10%);
        a {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }
  &--social-icons {
    text-align:right;
    padding-top: 5px;
    p {
      display: inline-block;
      font-size: 16px;
      vertical-align: top;
      padding-right: 5px;
      padding-top: 5px;
    }
    a {
      display: inline-block;
      padding-left: 5px;
      img {
        height: 35px;
      }
    }
  }
}
